import React from "react"
import * as colors from "../../constants/colors"

const CommentBlock = (props) => {
  const getSimpleTime = (created_at) => {
    const now = new Date()
    const then = props.new ? created_at : created_at.toDate()
    const time = Math.abs(now - then)
    const seconds = time / 1000
    if (seconds < 60) return `${Math.floor(seconds)}s`
    const minutes = seconds / 60
    if (minutes < 60) return `${Math.floor(minutes)}m`
    const hours = minutes / 60
    if (hours < 24) return `${Math.floor(hours)}h`
    const days = hours / 24
    if (days < 7) return `${Math.floor(days)}d`
    const weeks = days / 7
    if (weeks < 5) return `${Math.floor(weeks)}w`
    const months = days / 30
    if (months < 12) return `${Math.floor(months)}mo`
    const years = days / 365
    return `${Math.floor(years)}y`
  }

  return (
    <div style={styles.whole}>
      <p style={styles.content}>{props.content}</p>
      <p style={styles.time}>{getSimpleTime(props.date)}</p>
    </div>
  )
}
const styles = {
  whole: {
    width: "calc(100% - 30px)",
    padding: "7px 5px",
    margin: "10px",
    backgroundColor: "white",
    borderRadius: 5,
    color: colors.gray,
  },
  content: {
    margin: 0,
    padding: 0,
  },
  time: {
    color: colors.gray,
    margin: 0,
    padding: 0,
    opacity: 0.8,
  },
}
export default CommentBlock
