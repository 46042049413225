import React from 'react';
import * as colors from '../constants/colors';
const Footer = (props) => {
  if (props.mobile) return <div />;
  return (
    <div style={styles.whole}>
      <p>Site was created by Fajar Dirham, but you already knew that.</p>
      <p>Last updated Mar 2022.</p>
    </div>
  );
};

const styles = {
  whole: {
    width: '100%',
    color: 'white',
    height: 60,
    padding: '10px 0',
    backgroundColor: colors.gray,
    textAlign: 'center',
    fontSize: 'small',
  },
};

export default Footer;
