import React from 'react'

const LoadMore = props =>
{
    if ( props.finished ) return <div />
    if ( props.loading )
        return (
            <div style={ styles.whole }>
                loading... (yes, it's infinite scroll :))
            </div>
        )
    return (
        <div onClick={ props.onClick } style={ styles.whole }>
            load more
        </div>
    )
}

const styles = {
    whole: {
        height: 50,
        width: '100%',
        textAlign: 'center',
        fontSize: 'large',
        fontWeight: 'bold'
    },
}

export default LoadMore
