import React from 'react'

const Divider = props =>
{
    return (
        <div
            style={ {
                ...props.mobile ?
                    { height: 75 } :
                    { height: 75 }
            } }
        />

    )
}

export default Divider
