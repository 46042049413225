import React, { Component } from 'react'
import * as colors from '../constants/colors'
import firebase from '../constants/firebase'
import CommentBlock from './Comments/CommentBlock'
import LoadMore from './Comments/LoadMore'
import Warning from './Comments/Warning'
import Counts from './Hello/Counts'
import Divider from './Divider'

const ref = firebase.firestore().collection( "comments" )
export class Comments extends Component
{
    constructor ( props )
    {
        super( props )
        this.state = {
            typing: false,
            loading: false,
            comment: '',
            data: [],
            warned: false,
            showWarning: false,
            finished: false
        }
    }

    componentDidMount ()
    {

        this.getComments()
        window.addEventListener( "scroll", this.onScroll, true )
    }

    componentWillUnmount ()
    {
        window.removeEventListener( "scroll", this.onScroll )
    }

    onScroll = () =>
    {
        const { finish, data } = this.state
        if ( !finish )
        {
            const element = document.getElementById( 'wall' )
            const bottom1 =
                this.props.mobile

                    ? ( window.innerHeight + window.pageYOffset ) >= ( document.body.offsetHeight - 2 )
                    : ( element.scrollTop + element.clientHeight ) >= ( element.scrollHeight - 5 )

            if ( bottom1 && data.length )
            {
                this.getComments()
            }
        }
    }

    getComments = () =>
    {
        const { data, loading, finished } = this.state
        if ( !loading && !finished )
        {
            this.setState( { loading: true }, () =>
            {
                ref
                    .orderBy( 'date', 'desc' )
                    .limit( data.length + 1 )
                    .get().then( res =>
                    {
                        var lastVisible = res.docs[ res.docs.length - 1 ];
                        ref
                            .orderBy( 'date', 'desc' )
                            .limit( 10 )
                            .startAt( lastVisible )
                            .get().then( res =>
                            {
                                const resData = []
                                res.forEach( doc =>
                                {
                                    resData.push( doc.data() )
                                } )
                                this.setState( {
                                    data: this.state.data.concat( resData ),
                                    loading: false,
                                    finished: ( resData.length !== 10 )
                                } )
                            } )
                    } )
            } )
        }
    }

    handleChange = e =>
    {
        if ( !this.state.warned )
        {
            return this.setState( { showWarning: true } )
        }
        if ( this.state.showWarning ) return
        if ( e.target.value.includes( '\n' ) ) return this.handleSubmit()
        this.setState( {
            typing: ( e.target.value.length > 0 ),
            comment: e.target.value
        } )
    }

    handleSubmit = e =>
    {
        if ( e ) e.preventDefault()
        const newComment = { content: this.state.comment, date: new Date() }
        ref.add( newComment ).then( () =>
        {
            const ref2 = firebase.firestore().collection( "stats" ).doc( "counts" )
            ref2.update( { comments: firebase.firestore.FieldValue.increment( 1 ) } )
            newComment.new = true
            const new_data = [ newComment ]
            this.setState( {
                comment: '',
                data: new_data.concat( this.state.data )
            } )
        } )
    }

    showComments = () =>
    {
        if ( this.state.data )
            return this.state.data.map( c =>
            {
                return <CommentBlock
                    key={ c.date }
                    date={ c.date }
                    new={ c.new }
                    content={ c.content }
                />

            } )
    }

    showWarning = () =>
    {
        if ( this.state.showWarning ) return <Warning mobile={ this.props.mobile } close={ () => { this.setState( { showWarning: false, warned: true } ) } } />
        else return <div />
    }
    render ()
    {
        const { typing, comment, loading, finished } = this.state
        const { mobile } = this.props
        return (
            <section id='comments' style={ styles.whole }>
                <Divider mobile={ this.props.mobile } />
                { this.showWarning() }
                <h3 style={ styles.received }> Thoughts on fbdpages </h3>
                <Counts mobile={ mobile } />
                <form
                    style={ { ...styles.form, ...mobile ? styles.mobileForm : null } }
                    onSubmit={ this.handleSubmit }
                >
                    <textarea
                        type='text'
                        placeholder='add comment...'
                        style={ {
                            ...styles.input,
                            ...typing ? { borderColor: colors.gray } : {}
                        } }
                        value={ comment }
                        onChange={ this.handleChange }
                        maxLength={ 140 }
                    />
                    <button style={ styles.submit } type='submit' >
                        <span className='hover-orange'>
                            Submit
                </span>
                    </button>
                </form>
                <div
                    style={ mobile ? styles.mobileWall : styles.wall }
                    id='wall'
                >
                    { this.showComments() }
                    <LoadMore loading={ loading } finished={ finished } />
                </div>
                <div
                    style={ mobile ? styles.mobileBottom : styles.bottom }
                >
                </div>
            </section>
        )
    }
}

const styles = {
    whole: {
        padding: '50px 0 0 0',
        fontSize: 'large',
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    received: {
        padding: 0,
        margin: 0,
        fontSize: 'x-large',
        fontWeight: 'bold'
    },
    form: {
        backgroundColor: colors.slight,
        width: 'calc(95% - 20px)',
        padding: '5px 10px 20px 10px',
        borderRadius: '5px 5px 0 0',
        display: 'flex',
        marginTop: '30px',
        flexDirection: 'column'
    },
    mobileForm: {
        width: 'calc(100% - 20px)',
    },
    input: {
        padding: '10px',
        fontSize: 'medium',
        fontFamily: 'arial',
        resize: 'none',
        width: 'calc(100% - 20px)',
        color: colors.gray,
        backgroundColor: 'transparent',
        outline: 'none',
        border: 'solid',
        borderColor: colors.vague,
        borderWidth: '0 0 2px 0',
        margin: 0,
    },
    submit: {
        border: 0,
        backgroundColor: colors.gray,
        padding: '5px 10px',
        fontSize: 'medium',
        fontWeight: 'bold',
        borderRadius: 5,
        marginTop: '20px',
        color: 'white',
        marginLeft: 'auto'
    },
    wall: {
        width: '95%',
        height: 500,
        backgroundColor: colors.slight,
        overflowY: 'scroll',
    },
    mobileWall: {
        width: '100%',
        backgroundColor: colors.slight,
    },
    mobileBottom: {
        backgroundColor: colors.slight,
        width: '100%',
        height: 5,
        borderRadius: '0 0 5px 5px'
    },
    bottom: {
        backgroundColor: colors.slight,
        width: '95%',
        height: 20,
        borderRadius: '0 0 5px 5px'
    },
}

export default Comments
