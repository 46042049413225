import React, { useState, useEffect } from "react"
import firebase from "../../constants/firebase"
import * as colors from "../../constants/colors"
import views from "../../media/views.png"
import likes from "../../media/likes.png"
import likes_active from "../../media/likes_active.png"
import comments from "../../media/comments.png"
import comments_active from "../../media/comments_active.png"
import { motion, useAnimation } from "framer-motion"

const Counts = (props) => {
  const [hoverLike, setHoverLike] = useState(false)
  const [hoverComment, setHoverComment] = useState(false)
  const [stats, setStats] = useState({ views: 0, likes: 0, comments: 0 })
  const [loading, setLoading] = useState(false)
  const animLike = useAnimation()

  const ref = firebase.firestore().collection("stats").doc("counts")

  useEffect(() => {
    if (!loading)
      ref.onSnapshot((doc) => {
        setStats(doc.data())
        setLoading(true)
      })
  }, [ref, loading])

  const handleLike = async () => {
    ref.update({ likes: firebase.firestore.FieldValue.increment(1) })
    await animLike.start({
      scale: 1.2,
      opacity: 0.2,
      transition: { duration: 0.2 },
    })
    await animLike.start({
      scale: 1,
      opacity: 1,
      transition: { duration: 0.2 },
    })
  }

  return (
    <div
      style={{
        ...styles.whole,
        transition: "opacity .5s ease-in-out",
        ...(stats.views && stats.views !== 1 ? { opacity: 1 } : { opacity: 0 }),
      }}
    >
      <div style={styles.stat}>
        <img src={views} alt="views_logo" style={{ ...styles.logo }} />
        {stats ? stats.views : "0"}
        {props.mobile ? "" : " views"}
      </div>
      <div
        style={{
          ...styles.stat,
          ...(hoverLike ? { color: colors.orange } : {}),
        }}
        className="hover"
        onMouseEnter={() => setHoverLike(true)}
        onMouseLeave={() => setHoverLike(false)}
        onClick={handleLike}
      >
        <motion.div animate={animLike} style={{ ...styles.logo }}>
          <img
            src={hoverLike ? likes_active : likes}
            style={{ ...styles.logo, margin: 0 }}
            alt="likes_logo"
          />
        </motion.div>
        {stats ? stats.likes : "0"}
        {props.mobile ? "" : " likes"}
      </div>
      <a
        href="#comments"
        style={{
          ...styles.stat,
          ...(hoverComment ? { color: colors.orange } : {}),
        }}
        className="hover"
        onMouseEnter={() => setHoverComment(true)}
        onMouseLeave={() => setHoverComment(false)}
      >
        <img
          src={hoverComment ? comments_active : comments}
          alt="comments_logo"
          style={{ ...styles.logo }}
        />
        {stats ? stats.comments : "0"} {props.mobile ? "" : " comments"}
      </a>
    </div>
  )
}
const styles = {
  whole: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "large",
    fontWeight: "bold",
    marginTop: "8px",
    width: "100%",
  },
  stat: {
    padding: "0 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "5px 20px",
    whiteSpace: "nowrap",
  },
  logo: {
    height: "32px",
    width: "32px",
    margin: "0 8px",
  },
}

export default Counts
