import React, { useState } from 'react'
import * as colors from '../../constants/colors'
import { Link } from 'react-scroll'


const LinkBlock = props =>
{
    const [ active, setActive ] = useState( ( window.location.hash === ( '#' + props.section ) ) )
    const handleActive = () =>
    {
        if ( props.handleActive ) props.handleActive( props.text )
        setActive( true )
    }
    return (
        <Link
            spy={ true }
            hashSpy={ true }
            smooth={ true }
            offset={ 0 }
            duration={ 1 }
            to={ props.section }
            onSetActive={ () => handleActive() }
            onSetInactive={ () => setActive( false ) }
            className='hover'
            style={ {
                ...styles.whole,
                ...active
                    ? styles.active
                    : null
            } }
        >
            <img
                style={ styles.img }
                src={ active ? props.activeSrc : props.src }
                alt={ props.text + '_logo' }
            />
            { props.text }
        </Link>
    )
}

const styles = {
    whole: {
        color: 'white',
        fontSize: 'small',
        textDecoration: 'none',
        padding: '10px 0 5px 0',
        width: '20%',
        border: 'solid',
        borderWidth: '0 0 2px 0',
        borderColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    active: {
        color: colors.orange,
        borderColor: colors.orange
    },
    img: {
        height: 26,
        width: 26,
        objectFit: 'cover'
    },
}

export default LinkBlock
