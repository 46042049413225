import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import MainPage from './MainPage'
import NymPrivacy from './pages/NymPrivacy';
import NymThirdParty from './pages/NymThirdParty';
import NymTnC from './pages/NymTnC';

export class App extends Component
{
  render ()
  {
    return (
      <Router basename={ process.env.PUBLIC_URL }>
        <Switch>
          <Route path="/nym/privacy/" component={ NymPrivacy } />
          <Route path="/nym/third-party-credits/" component={ NymThirdParty } />
          <Route path="/nym/terms-and-conditions" component={ NymTnC } />
          <Route path="/" component={ MainPage } />
        </Switch>
      </Router>
    );
  }
}

const styles = {
}
export default App;
