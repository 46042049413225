import React from 'react'
import * as colors from '../../constants/colors'
import logo from '../../media/fbd_white.png'

const MobileHeader = props =>
{
    return (

        <div style={ styles.whole }>
            {props.title === 'Home'
                ?
                <>
                    <img src={ logo } alt='logo' style={ styles.img } />
                    fbdpages
                </>
                : props.title }
        </div>
    )
}



const styles = {
    whole: {
        width: '100%',
        backgroundColor: colors.gray,
        position: 'fixed',
        left: 0,
        color: 'white',
        textAlign: 'left',
        padding: '16px',
        fontWeight: 'bold',
        fontSize: 'large',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        top: 0,
        zindex: 50,
    },
    img: {
        width: 20,
        height: 20,
        marginRight: 10,
        objectFit: 'cover'
    },
}
export default MobileHeader
