import React, { useState, useEffect } from 'react'
import * as colors from '../../constants/colors'
import * as sizes from '../../constants/sizes'

const Warning = props =>
{
    const [ read, setRead ] = useState( false )

    useEffect( () =>
    {
        if ( props.mobile )
            document.body.style.overflow = "hidden"

        setTimeout( () =>
        {
            setRead( true )
        }, 10 * 1000 )

        return () =>
        {
            if ( props.mobile )
                document.body.style.overflow = 'visible'
        }
    }, [ props.mobile ] )

    return (
        <>
            { props.mobile ? null
                :
                <div
                    style={ styles.bg }
                />
            }
            <div
                style={ props.mobile ? styles.mobile : styles.whole }
            >
                <div style={ styles.content }>
                    <h1
                        style={ props.mobile ? styles.mobileTitle : styles.title }
                    >
                        BEFORE COMMENTING...
                        </h1>
                    <div
                        style={ props.mobile ? styles.mobileTxt : styles.txt }
                    >
                        <p>If you want to contact me for serious purposes, email me at fajarletters@gmail.com. </p>
                        <p>Don't harass or say anything mean or stupid. This site is 80% for professional purposes.
If things get bad, I will make it so that comments need to get approved before being shown.</p>
                        <p>Creating an anonymous forum on my personal web page is 1000% a bad idea, but we'll see where this goes.</p>
                        <p>The button below will be enabled after about 10 seconds. Then you can comment.</p>
                    </div>
                    <button
                        style={ { ...styles.button, ...read ? { color: colors.orange, opacity: 1 } : { opacity: 0.5 } } }
                        disabled={ !read }
                        className={ read ? 'hover' : '' }
                        onClick={ () => { props.close() } }
                    >
                        I understand
</button>
                </div>
            </div>
        </>
    )
}

const styles = {
    bg: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        opacity: 0.2,
        backgroundColor: colors.gray,
    },
    whole: {
        position: 'fixed',
        width: sizes.webWidth,
        minHeight: sizes.webWidth / 1.8,
        zIndex: 200,
        top: '50%',
        left: '50%',
        marginTop: -sizes.webWidth / 3, /* Negative half of height. */
        marginLeft: -sizes.webWidth / 2, /* Negative half of width. */
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        overflow: 'hidden'
    },
    mobile: {
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 200,
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        backgroundColor: colors.gray,
        padding: '5px 20px',
        color: colors.orange,
        fontSize: 'x-large',
        textAlign: 'right',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        padding: '10px'
    },
    title: {
        padding: 0,
        margin: 0,
        marginTop: '10px',
        textAlign: 'center',
        fontSize: 'x-large',
        width: '80%'
    },
    mobileTitle: {
        padding: 0,
        margin: 0,
        textAlign: 'center',
        fontSize: 'large',
    },
    txt: {
        fontSize: 'large',
        marginLeft: '20px',
        paddingRight: 20
    },
    mobileTxt: {
        fontSize: 'medium',
        marginLeft: '20px',
        width: '80%',
        paddingRight: 20
    },
    button: {
        border: 0,
        backgroundColor: colors.gray,
        padding: '16px',
        fontSize: 'medium',
        fontWeight: 'bold',
        borderRadius: 5,
        marginTop: '20px',
        color: 'white',
    },
}

export default Warning
