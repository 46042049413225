import React from 'react'
import * as colors from '../../constants/colors'

const DownloadDoc = () =>
{
    return (
        <div style={ styles.whole }>
            <a href='../../media/Fajar-Dirham-Resume.pdf' download={ true } style={ styles.button }>Download resume</a>
            <h3 style={ styles.text }>
                Preview available on desktop site
            </h3>
        </div>
    )
}

const styles = {
    whole: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    text: {
        padding: 0,
        margin: 0,
    },
    button: {
        padding: '14px',
        backgroundColor: colors.orange,
        color: 'white',
        fontSize: 'x-large',
        fontWeight: 'bold',
        borderRadius: 90,
        marginTop: 10
    },
}
export default DownloadDoc
