import React from "react"
import LinkBlock from "./LinkBlock"
import home from "../../media/Header/home.png"
import home_active from "../../media/Header/home_active.png"
import projects from "../../media/Header/projects.png"
import projects_active from "../../media/Header/projects_active.png"
import resume from "../../media/Header/resume.png"
import resume_active from "../../media/Header/resume_active.png"
import about from "../../media/Header/about.png"
import about_active from "../../media/Header/about_active.png"
import comments from "../../media/Header/comments.png"
import comments_active from "../../media/Header/comments_active.png"

const LinkGroup = (props) => {
  return (
    <ul style={styles.whole}>
      <LinkBlock
        text={"Home"}
        section={"hello"}
        src={home}
        handleActive={props.handleActive}
        activeSrc={home_active}
      />
      <LinkBlock
        text={"Resume"}
        section={"resume"}
        src={resume}
        handleActive={props.handleActive}
        activeSrc={resume_active}
      />
      <LinkBlock
        text={"About"}
        section={"about"}
        src={about}
        handleActive={props.handleActive}
        activeSrc={about_active}
      />
      <LinkBlock
        text={"Comments"}
        section={"comments"}
        src={comments}
        handleActive={props.handleActive}
        activeSrc={comments_active}
      />
    </ul>
  )
}

const styles = {
  whole: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    listStyleType: "none",
  },
}

export default LinkGroup
