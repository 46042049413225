import React from 'react';
import Divider from './Divider';
const About = (props) => {
  return (
    <section id='about' style={styles.whole}>
      <Divider mobile={props.mobile} />
      <h3 style={styles.title}>About me</h3>
      <div style={styles.text}>
        <p style={styles.line}>
          I'm an ambitious tryhard, I don't take myself too seriously though.
        </p>
        <p style={styles.line}>
          Stuff I currently like: One Piece, Demon Slayer, DnD, Chess, Poker.{' '}
        </p>
      </div>
    </section>
  );
};

const styles = {
  whole: {
    padding: '50px 0 0 0',
    fontSize: 'large',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 0,
  },
  text: {
    width: '80%',
  },
  title: {
    margin: 0,
    marginBottom: 16,
    padding: 0,
    fontSize: 'x-large',
  },
  line: {
    margin: 0,
    padding: '3px 0 ',
  },
};

export default About;
