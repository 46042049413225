import React from "react"
import Headshot from "../../media/Headshot.jpeg"
import * as sizes from "../../constants/sizes"

const DisplayImg = () => {
  return <img style={styles.whole} src={Headshot} alt="me" />
}

const styles = {
  whole: {
    width: sizes.displayImg,
    height: sizes.displayImg,
    objectFit: "cover",
    borderRadius: 180,
  },
}

export default DisplayImg
