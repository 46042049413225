import React from "react"
import mail from "../../media/mail.png"
import ig from "../../media/ig.png"
import location from "../../media/location.png"

const Contact = (props) => {
  return (
    <section
      id="contact"
      style={{ ...styles.whole, ...(props.mobile ? styles.mobile : {}) }}
    >
      <a
        href="mailto:fajarletters@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.contact}
      >
        <img
          src={mail}
          alt="mail_logo"
          style={{
            ...styles.logo,
            ...(props.invert ? { filter: "invert(100%)" } : {}),
          }}
        />
        <span className="hover-orange">fajarletters@gmail.com</span>
      </a>
      <a
        href="https://www.instagram.com/fbd.drops/"
        rel="noopener noreferrer"
        target="_blank"
        style={styles.contact}
      >
        <img
          src={ig}
          alt="ig_logo"
          style={{
            ...styles.logo,
            ...(props.invert ? { filter: "invert(100%)" } : {}),
          }}
        />
        <span className="hover-orange">Projects and Experience</span>
      </a>
    </section>
  )
}

const styles = {
  whole: {
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSize: "large",
    justifyContent: "center",
  },
  mobile: {
    flexDirection: "column",
    fontSize: "medium",
    alignItems: "center",
  },
  invert: {
    color: "white",
  },
  contact: {
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    height: "16px",
    width: "16px",
    margin: "0 5px",
  },
}

export default Contact
