import React, { useState } from 'react'
import * as colors from '../constants/colors'
import * as sizes from '../constants/sizes'
import LinkGroup from './Header/LinkGroup'
import MobileHeader from './Header/MobileHeader'

const Header = props =>
{
    const [ title, setTitle ] = useState( 'Home' )
    const mobileHeader = () =>
    {
        if ( !props.mobile ) return <div />
        return (
            <MobileHeader title={ title } />
        )
    }
    return (
        <>
            {mobileHeader() }
            <div
                style={ {
                    ...styles.whole,
                    ...props.mobile ? styles.mobile : { top: 0 }
                } }
            >
                <div style={ styles.content }>
                    <LinkGroup mobile={ props.mobile } handleActive={ val => setTitle( val ) } />
                </div>
            </div>
        </>
    )
}

const styles = {
    whole: {
        width: '100%',
        backgroundColor: colors.gray,
        position: 'fixed',
        left: 0,
        zIndex: 50,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    content: {
        width: sizes.webWidth,
    },
    mobile: {
        bottom: 0
    },
}

export default Header
