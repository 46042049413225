import React from 'react'
import resume from '../../media/Fajar-Dirham-Resume.pdf'

const ResumeDoc = () =>
{
    return (
        <embed src={ resume } style={ styles.whole } />
    )
}

const styles = {
    whole: {
        width: 700,
        height: 950,
        borderRadius: '10px'
    },
}
export default ResumeDoc
