import React, { useEffect } from 'react'
import { gray } from '../../constants/colors'
import { motion, useAnimation } from 'framer-motion'

const MobileMsg = props =>
{

    const anim = useAnimation()

    useEffect( () =>
    {
        animateBar()
    }, [] )

    const animateBar = async () =>
    {
        await anim.start( {
            top: 100,
            transition: { duration: 1, delay: 4 }
        } )
        await anim.start( {
            top: -100,
            transition: { duration: 1, delay: 4 }
        } )
    }

    return (
        <motion.div

            style={ styles.whole }
            animate={ anim }
        >
            { !props.mobile

                ? 'Site is also optimized for mobile!'
                : 'Site is also optimized for desktop!'
            }

        </motion.div>
    )
}

const styles = {
    whole: {
        position: 'fixed',
        top: -100,
        zIndex: 1,
        opacity: 1,
        backgroundColor: 'black',
        padding: '10px',
        fontSize: 'large',
        textAlign: 'center',
        color: 'white'
    },
}
export default MobileMsg
