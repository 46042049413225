import React from 'react'
import Divider from './Divider'
import DownloadDoc from './Resume/DownloadDoc'
import ResumeDoc from './Resume/ResumeDoc'

const Resume = props =>
{
    const showResume = () =>
    {
        if ( props.mobile ) return <DownloadDoc />
        return <ResumeDoc />
    }
    return (
        <section id='resume' style={ styles.whole }>
            <Divider mobile={ props.mobile } />
            {showResume() }
        </section>
    )
}

const styles = {
    whole: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
}
export default Resume
