import React from 'react'
import "../App.css"

function NymThirdParty ()
{
  return (
    <div>
      <head>
        <meta charset='utf-8' />
        <meta name='viewport' content='width=device-width' />
        <title>Nym Third Party Credits</title>
      </head>
      <strong>Third Party Credits</strong> <p>
        FBD created the Nym app using React Native, Expo, and Firebase.
        Express and NodeJS was used to create the API that this app uses to communicate with the database.
                </p> <p>
        Icons used in this app are a mix of FBD's own creation, Entypo's, Freepik's, and more.
        If there are icons / graphics that you or your company has created that you feel has not been attributed properly, please contact FBD.
                </p>
      <p><strong>Contact Us</strong></p> <p>
        If you have any questions, comments, or concerns about the graphics / technologies used in this app,
        do not hesitate to contact me at fajarletters@gmail.com.
                </p>
    </div>
  )
}

export default NymThirdParty
