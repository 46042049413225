import React from 'react';
import DisplayImg from './Hello/DisplayImg';
import '../App.css';
import Contact from './Hello/Contact';
import Counts from './Hello/Counts';
import MobileMsg from './Hello/MobileMsg';

const Hello = (props) => {
  return (
    <section id='hello' style={styles.whole}>
      <MobileMsg mobile={props.mobile} />
      <DisplayImg />
      <div style={styles.text}>
        <h1 style={styles.name}>Fajar B Dirham</h1>
        <Contact mobile={props.mobile} />
        <div style={styles.containerBlurb}>
          <p style={styles.blurb}>4th Year Undergraduate</p>
          <p style={styles.blurb}>
            Computer Engineering @ {props.mobile && <br />} UC San Diego
          </p>
        </div>
        <Counts mobile={props.mobile} />
        <h1 style={styles.want}>
          LOOKING FOR SOFTWARE ENGINEERING POSITIONS STARTING SUMMER 2022
        </h1>
      </div>
    </section>
  );
};
const styles = {
  whole: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
  },
  text: {
    textAlign: 'center',
    width: '80%',
    marginTop: '10px',
  },
  name: {
    padding: 0,
    margin: 0,
    fontSize: 'xxx-large',
    marginBottom: '8px',
  },
  containerBlurb: {
    margin: '20px 0',
  },
  blurb: {
    margin: '5px 0',
    padding: 0,
    width: '100%',
    overflowWrap: 'break-word',
  },
  want: {
    padding: 0,
    margin: '15px 0 0 0',
    fontSize: 'xxx-large',
  },
  info: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: 'large',
    justifyContent: 'center',
  },
  contact: {
    fontWeight: 'bold',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: '16px',
    width: '16px',
    margin: '0 5px',
  },
};

export default Hello;
