import React, { Component } from "react"
import { isMobile } from "react-device-detect"
import * as sizes from "./constants/sizes"
import * as colors from "./constants/colors"
import Header from "./layout/Header"
import Hello from "./layout/Hello"
import Projects from "./layout/Projects"
import "./App.css"
import Resume from "./layout/Resume"
import About from "./layout/About"
import Footer from "./layout/Footer"
import firebase from "./constants/firebase"
import Comments from "./layout/Comments"

export default class MainPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: isMobile,
    }
  }

  componentDidMount() {
    this.updateViews()
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    let currentmobile = window.innerWidth <= 760
    if (currentmobile !== this.state.mobile) {
      this.setState({ mobile: currentmobile })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }

  updateViews = () => {
    const ref = firebase.firestore().collection("stats").doc("counts")
    ref.update({ views: firebase.firestore.FieldValue.increment(1) })
  }

  render() {
    return (
      <div style={styles.whole}>
        <div style={this.state.mobile ? styles.mobile : styles.app}>
          <Header mobile={this.state.mobile} />
          <Hello mobile={this.state.mobile} />
          {/* <Projects
            mobile={ this.state.mobile }
          /> */}
          <Resume mobile={this.state.mobile} />
          <About mobile={this.state.mobile} />
          <Comments mobile={this.state.mobile} />
        </div>
        <Footer mobile={this.state.mobile} />
      </div>
    )
  }
}

const styles = {
  whole: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: colors.slight,
    color: colors.gray,
    justifyContent: "flex-start",
  },
  mobile: {
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
    paddingBottom: 60,
  },
  app: {
    width: sizes.webWidth,
    overflow: "hidden",
    minHeight: "100vh",
    padding: "0 20px",
    backgroundColor: "white",
    paddingBottom: 50,
  },
}
