import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyDKA7W9pZSoDYV41j4W9RMg3LSxwItJ8oU",
    authDomain: "fbd-pages.firebaseapp.com",
    databaseURL: "https://fbd-pages.firebaseio.com",
    projectId: "fbd-pages",
    storageBucket: "fbd-pages.appspot.com",
    messagingSenderId: "1014067992033",
    appId: "1:1014067992033:web:c1eac7581169aec6a7eea0",
    measurementId: "G-84SNWDX4KP"
};
// Initialize Firebase
firebase.initializeApp( firebaseConfig );
firebase.analytics();
firebase.functions();

export default firebase;